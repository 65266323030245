@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@200;300;500;600&family=Prompt:wght@200;300;400&family=Sarabun:wght@200;300;400&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f5f4f4;
  border-radius: 5px;
}


::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 5px;
}


::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;

}

.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}

.customNodeBody {
  min-width: 100px;
  padding: 5px 15px;
  border: 1px solid #9e9e9e;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.customNode:before {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  height: 10px;
  width: 20px;
  transform: translate(-50%, 0);
  background: #d6d5e6;
  z-index: 1000;
  line-height: 1;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #9e9e9e;
}

div.customHandle {
  width: 100%;
  height: 100%;
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
}

.blinking-warning-icon {
  animation: blink 1s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
    /* transform: scale(1); */
  }

  50% {
    opacity: 0.5;
    /* transform: scale(1.2); */
  }
}